.app {
  height: 100%;
  width: 100%;
}

.logo {
  height: 80px;
}

.content {
  min-height: calc(100vh - 48px - 70px);
}

.editable {
  border: 1px solid transparent;
  transition: background-color 0.3s linear;
  width: 100%;
  border-color: #eee;
  &:hover {
    cursor: pointer;
    background-color: rgba(250, 250, 250);
  }
}

@primary-color: #1DA57A;@layout-header-background: white;