.header {
  background-color: #fff;
  display: flex;
  min-height: 48px;
  align-items: center;
  padding: 0 26px;
  border-bottom: 1px solid #f0f0f0;
}

.right {
  margin-left: auto;
  display: flex;
  align-items: center;
}

@primary-color: #1DA57A;@layout-header-background: white;